import apiService from '@/core/common/services/api.service';
const moment = require('moment');

export const CREATE_UMFRAGE = 'umfragen.createUmfrage';
export const GET_UMFRAGEN = 'umfragen.getUmfragen';
export const SET_UMFRAGEN = 'umfragen.setUmfragen';
export const DELETE_UMFRAGE = 'umfragen.deleteUmfragen';
export const GET_ACTIVE_UMFRAGEN = 'umfragen.getActiveUmfragen';
export const SET_ACTIVE_UMFRAGEN = 'umfragen.setActiveUmfragen';
export const TOGGLE_ACTIVE_UMFRAGE = 'umfragen.toggleActiveUmfrage';
export const SAVE_ANTWORT = 'umfragen.saveAntwort';

const state = {
  umfragen: [],
  activeUmfragen: null,
  timeUpdated: null,
};
const actions = {
  [GET_UMFRAGEN](context) {
    return apiService.get('/umfrage?$orderby=createdDateUtc desc').then(response => {
      const result = response.data.result;
      context.commit(SET_UMFRAGEN, result);
    });
  },
  [TOGGLE_ACTIVE_UMFRAGE](context, params) {
    return apiService.put(`umfrage/${params.umfrageId}/toggleActive`).then(() => {
      getActiveUmfrage(context);
    });
  },
  [GET_ACTIVE_UMFRAGEN](context) {
    if (!context.state.timeUpdated || moment().diff(moment(context.state.timeUpdated), 'minutes') > 5) {
      getActiveUmfrage(context);
    }
  },
  [CREATE_UMFRAGE](context, params) {
    return apiService.post('/umfrage', params).then(response => {
      const result = response.data.result;
      context.commit(SET_ACTIVE_UMFRAGEN, result);
    });
  },
  [SAVE_ANTWORT](context, params) {
    const { antwort1, antwort2, antwort3, kommentar, umfrageId } = params;
    return apiService
      .post(`/umfrage/${umfrageId}/antwort`, {
        antwort1,
        antwort2,
        antwort3,
        kommentar,
      })
      .then(() => getActiveUmfrage(context));
  },
  [DELETE_UMFRAGE](context, params) {
    const { umfrageId } = params;
    return apiService.delete(`/umfrage/${umfrageId}`);
  },
};

const getters = {
  getActiveUmfrageAntwortenUserIds(state) {
    return state.activeUmfragen
      ? state.activeUmfragen.map(umfrage => umfrage.antworten.map(a => a.userId)).join()
      : [];
  },
};

const getActiveUmfrage = context => {
  apiService.get('/umfrage?$filter=isActive eq true').then(response => {
    context.state.timeUpdated = moment();
    const result = response.data.result;
    context.commit(SET_ACTIVE_UMFRAGEN, result);
  });
};

const mutations = {
  [SET_UMFRAGEN](state, umfragen) {
    state.umfragen = umfragen;
  },
  [SET_ACTIVE_UMFRAGEN](state, activeUmfragen) {
    state.activeUmfragen = activeUmfragen;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
