<template>
  <div>
    <div v-if="formattedData?.length === 0" class="p-4">
      <h6>Der Verlauf wird erst ab dem 17.01. gespeichert. Seitdem gibt es keine Änderungen!</h6>
    </div>
    <div v-for="(change, i) in formattedData" :key="change.key + ' ' + change.date" class="mb-5">
      <div class="d-flex">
        <span>
          {{ change.nameOfObjectEditor }} hat
          <b>{{ change.formattedKey }}</b>
          geändert.
        </span>
        <div
          style="color: rgba(0, 0, 0, 0.5); cursor: pointer"
          class="ml-6"
          :id="'date-change' + uniqueId + '-' + change.date + '-' + i"
        >
          {{ change.formattedDate }}
        </div>
      </div>
      <div class="pl-2" style="color: gray; font-size: 0.9rem" v-if="change.additionalIdentifier">
        {{ change.additionalIdentifier }}
      </div>

      <div class="d-flex p-2 align-items-center" style="gap: 12px">
        <span class="d-flex align-items-center" v-html="change.oldValueFormatted"></span>

        <span style="font-size: 20px">&rarr;</span>

        <span v-html="change.newValueFormatted"></span>
        <b-tooltip
          :target="'date-change' + uniqueId + '-' + change.date + '-' + i"
          triggers="hover"
          placement="right"
        >
          <span style="font-size: 1rem">{{ formatDate(change.date) }}</span>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { format, formatDistanceToNow, isAfter } from 'date-fns';
import de from 'date-fns/locale/de';
import { transferszenarioOptions } from '@/core/common/helpers/utils.js';
import currenciesJson from '@/core/common/helpers/currencies.json';

export default {
  props: {
    data: { type: Object },
  },
  data() {
    return {
      ignoredField: 'LastModifiedUserId',
      uniqueId: Math.random().toString(36).substring(2, 9),
      formatKeys: {
        // Reisedaten
        Hotelnaechte: 'die Hotelnächte',
        KalkPax: 'die Kalk Pax',
        ErwartetePax: 'die erwarteten Pax',
        ErwarteteTermindurchfuehrungsquote: 'die erw. Termindurchführungsquote',
        Vorverlaengerung: 'die Vorverlängerung',
        DurchfuehrbareMinPax: 'die durchführbare Min Pax',
        MinPax: 'die Min Pax',
        MaxPax: 'die Max Pax',
        VornachtMoeglich: 'Vornacht möglich',
        EinkaufspreisVornachtDzProPerson: 'die EK kalk. Vornacht DZ p.P.',
        // Releaseinformationen
        Releasefrist: 'die Releasefrist', // Sind mehrere und werden mit komma getrennt
        IsKritischeDestination: 'die kritische Destination',
        // Transfer
        TransferszenarioHin: 'das Transferszenario Hin',
        TransferszenarioRueck: 'das Transferszenario Rück',
        TransferkostenWaehrung: 'die Transferkosten Währung',
        KommentarTransferszenarioHin: 'den Kommentar TS Hin',
        KommentarTransferszenarioRueck: 'den Kommentar TS Rück',
        TransferkostenHin: 'die Transferkosten Hin',
        TransferkostenRueck: 'die Transferkosten Rück',
        TransferdauerHin: 'die Transferdauer Hin',
        TransferdauerRueck: 'die Transferdauer Rück',
        // Flugdaten
        SkrFlugangebot: 'das SKR Flugangebot',
        ZiellandZielflughaefen: 'die Zielland-Zielflughäfen', // wird im Verlauf noch nicht angezeigt
        ZiellandAbflugflughaefen: 'die Zielland-Abflugflughäfen', // wird im Verlauf noch nicht angezeigt
        OvernightOutbound: 'den Overnight Outbound',
        OvernightInbound: 'den Overnight Inbound',
        ZiellandZielflughafenPrio: 'die Zielland-Zielflughafen Priorität', // wird im Verlauf noch nicht angezeigt
        ZiellandAbflugFlughafenPrio: 'die Zielland-Abflugflughafen Priorität', // wird im Verlauf noch nicht angezeigt
        HotelstartVorLandung: 'den Hotelstart vor Landung',
        RueckflugNachHotelende: 'den Rückflug nach Hotelende',
        FruehesteAnkunft: 'die früheste Ankunft',
        SpaetesteAnkunft: 'die späteste Ankunft',
        FruehesterAbflug: 'den frühesten Abflug',
        SpaetesterAbflug: 'den spätesten Abflug',
        RestriktionenHinRollover: 'die Restriktionen Hin Rollover',
        RestriktionenRueckRollover: ' die Restriktionen Rück Rollover',
        // Inlandsflüge (können mehrere Sein)
        Abflughafen: 'den Abflughafen',
        Zielflughafen: 'den Zielflughafen',
        Einkauf: 'den Einkauf',
        MinimaleUmsteigezeit: 'die minimale Umsteigezeit',
        ZielflughafenIataCode: 'den Zielflughafen',
        AbflughafenIataCode: 'den Abflughafen',
      },
    };
  },
  computed: {
    ...mapState({
      users: state => state.users['users'],
    }),
    formattedData() {
      let aktuelleWaehrung = 'EUR';

      return Object.entries(this.data)
        .filter(row => new Date(row[0]).getFullYear() >= 2025)
        .reduce((acc, curr) => {
          const changeDate = curr[0];
          const { changes, lastModifiedUserId } = curr[1];

          //schaut, ob sich die Währung geändert hat
          const aenderungDerWaehrung = changes.find(change => change.key === 'TransferkostenWaehrung');
          if (aenderungDerWaehrung) {
            aktuelleWaehrung = aenderungDerWaehrung.newValue;
          }

          for (const change of changes) {
            if (change.key === this.ignoredField) {
              continue;
            }

            const { newValue, oldValue, key, additionalIdentifier } = change;
            const objectEditor = this.users.find(user => user.id === lastModifiedUserId);
            const nameOfObjectEditor = objectEditor
              ? objectEditor.givenName + ' ' + objectEditor.surname
              : '-';
            const formattedDate = this.getDateDistanceToNow(changeDate);

            const currencySymbol = currenciesJson[aktuelleWaehrung]?.symbol || '€';
            const newValueFormatted = this.getValueFormatted(newValue, key, currencySymbol);
            const oldValueFormatted = this.getValueFormatted(oldValue, key, currencySymbol);

            acc.push({
              oldValue,
              oldValueFormatted,
              newValue,
              newValueFormatted,
              nameOfObjectEditor,
              date: changeDate,
              formattedDate,
              key,
              additionalIdentifier,
              formattedKey: this.formatKeys[key],
            });
          }
          return acc;
        }, [])
        .sort((a, b) => (isAfter(new Date(b.date), new Date(a.date)) ? 1 : -1));
    },
  },
  methods: {
    getDateDistanceToNow(dateString) {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true, locale: de });
    },
    formatDate(value) {
      return format(new Date(value), 'dd.MM.yyyy  HH:mm') + ' Uhr';
    },
    getValueFormatted(value, key, currencySymbol) {
      if (value === null) return '<span class="badge badge-secondary">---</span>';

      if (
        [
          'IsKritischeDestination',
          'HotelstartVorLandung',
          'RueckflugNachHotelende',
          'RestriktionenRueckRollover',
          'RestriktionenHinRollover',
          'VornachtMoeglich',
        ].includes(key)
      ) {
        if (value === false)
          return '<span class="badge badge-secondary" style="padding: 4px; font-size: 1rem;"><span class="badge badge-danger p-1"><i class="fas fa-1x fa-ban"></i></span></span>';
        return '<span class="badge badge-secondary" style="padding: 4px; font-size: 1rem;"><span class="badge badge-success p-1"><i class="fas fa-1x fa-check"></i></span></span>';
      }

      if (key === 'Einkauf') {
        const einkaufsValues = {
          0: 'SKR',
          1: 'DMC',
          2: 'SKR und DMC',
        };
        return `<span class="badge badge-secondary" style="font-size: 0.9rem">${einkaufsValues[value]}</span>`;
      }

      if (['TransferkostenHin', 'TransferkostenRueck'].includes(key)) {
        return (
          '<span class="badge badge-secondary" style="font-size: 0.9rem">' +
          value +
          ' ' +
          currencySymbol +
          '</span>'
        );
      }

      if (key === 'TransferszenarioHin' || key === 'TransferszenarioRueck') {
        return (
          '<span class="badge badge-secondary" style="font-size: 0.9rem">' +
          transferszenarioOptions[value].text +
          '</span>'
        );
      }

      if (key === 'OvernightOutbound' || key === 'OvernightInbound') {
        if (value === 0) return '<span class="badge badge-secondary" style="font-size: 0.9rem">0</span>';
        if (value === 1) return '<span class="badge badge-secondary" style="font-size: 0.9rem">+1</span>';
        if (value === 2) return '<span class="badge badge-secondary" style="font-size: 0.9rem">+2</span>';
        return '<span class="badge badge-secondary" style="font-size: 0.9rem">' + value + '</span>';
      }

      if (['KommentarTransferszenarioHin', 'KommentarTransferszenarioRueck'].includes(key)) {
        return value;
      }

      if (key === 'EinkaufspreisVornachtDzProPerson' || key === 'SkrFlugangebot') {
        return '<span class="badge badge-secondary" style="font-size: 0.9rem">' + value + '€' + '</span>';
      }

      if (key === 'Releasefrist') {
        return (
          '<span class="badge badge-secondary" style="font-size: 0.9rem">' +
          String(value).split(',').join(', ') +
          '</span>'
        );
      }

      if (
        key === 'FruehesteAnkunft' ||
        key === 'SpaetesteAnkunft' ||
        key === 'FruehesterAbflug' ||
        key === 'SpaetesterAbflug'
      ) {
        return (
          '<span class="badge badge-secondary" style="font-size: 0.9rem">' +
          value.substring(0, 5) +
          ' Uhr' +
          '</span>'
        );
      }

      return '<span class="badge badge-secondary" style="font-size: 0.9rem">' + value + '</span>';
    },
  },
};
</script>
<style scoped>
:deep(p) {
  padding: 0;
  margin-bottom: 0;
}
</style>
