<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div class="d-flex">
        <div class="flex-grow-1">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center mr-3">
              <template v-if="!isInitialLoading && reiseterminObj">
                <div style="display: flex; flex-direction: row; gap: 4px; align-items: center" class="mr-4">
                  <a
                    class="d-flex align-items-center flex-wrap text-dark text-nowrap font-size-h5 font-weight-bold mr-3"
                  >
                    {{ reiseterminObj.reiseterminkuerzel }}
                  </a>
                  <div v-if="reiseterminObj?.ursprungsreiseterminId" style="cursor: pointer">
                    <a
                      target="_blank"
                      :href="'/#/reisetermine/' + reiseterminObj?.ursprungsreiseterminId"
                      v-b-tooltip.hover
                      title="Ursprungstermin"
                    >
                      <i class="far fa-calendar-minus fa-lg"></i>
                    </a>
                  </div>
                  <div v-if="reiseterminObj?.ersatztermin" style="cursor: pointer">
                    <a
                      target="_blank"
                      :href="'/#/reisetermine/' + reiseterminObj?.ersatztermin.id"
                      v-b-tooltip.hover
                      title="Ersatztermin"
                    >
                      <i class="far fa-calendar-check fa-lg"></i>
                    </a>
                  </div>
                </div>

                <b-badge class="mr-4">{{ getStateGerman(reiseterminObj?.state) }}</b-badge>
                <b-badge class="mr-4">{{ getInBlankAngelegt(reiseterminObj?.reiseterminSyncState) }}</b-badge>
                <ReleaseprozessIcons class="mr-4" :reiseterminObject="reiseterminObj" />
              </template>
              <b-skeleton v-if="isInitialLoading" height="24px" width="600px"></b-skeleton>
              <div v-if="!isInitialLoading && reiseterminObj" class="d-flex flex-wrap my-2">
                <a class="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  <i class="flaticon-earth-globe mr-1"></i>
                  {{ $t('REISEN.REGIONS.' + reiseterminObj.reise.region) }}
                </a>
                <a class="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  <i class="flaticon-earth-globe mr-1"></i>
                  {{ $t('REISEN.COUNTRIES.' + reiseterminObj.reise.country) }}
                </a>
              </div>
            </div>
            <div v-if="reiseterminObj" class="my-lg-0 my-1">
              <b-button
                v-if="$route.name === 'reisetermine-view'"
                v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD]"
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="bearbeiten"
                title="Reise bearbeiten"
                :href="
                  '#/reisen/' +
                  reiseterminObj.reiseterminkuerzel?.substring(0, 6) +
                  '/reisestammdaten?reiseterminAbreisedatum=' +
                  reiseterminObj.abreisedatum.substring(0, 10)
                "
              >
                <i class="flaticon-edit icon-1x" />
              </b-button>

              <b-button
                v-if="$route.name === 'reisetermin-view-releasepruefung'"
                v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD, Role.DOM]"
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="bearbeiten"
                title="Reise bearbeiten"
                :to="{
                  name: 'reisen-edit-view',
                  params: { reisekuerzel: reiseterminObj.reise.reisekuerzel },
                }"
              >
                <i class="flaticon-edit icon-1x" />
              </b-button>

              <b-button
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="reiseunterlagen"
                title="Reiseunterlagen"
                :href="
                  'https://www.skr.de/reiseinfos/' +
                  reiseterminObj?.reiseterminkuerzel.replaceAll('-', '').replaceAll('_', '') +
                  '/L/'
                "
                target="_blank"
              >
                <i class="far fa-list-alt fa-lg" />
              </b-button>

              <b-button
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="reisewiki"
                title="Reisewiki"
                :href="'https://skr.de/+' + reiseterminObj.reise.reisekuerzel"
                target="_blank"
              >
                <i class="fab fa-wikipedia-w" />
              </b-button>

              <b-button
                v-if="kontaktdatenlisteLink"
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                aria-label="reisewiki"
                title="Kontaktdatenliste"
                :href="kontaktdatenlisteLink"
                target="_blank"
              >
                <i class="flaticon2-user-outline-symbol" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Ab 25 -->
      <ReiseterminViewDetails
        v-if="$route.name === 'reisetermine-view'"
        :reiseterminObj="reiseterminObj"
      ></ReiseterminViewDetails>
      <ReiseterminViewReleasepruefung
        v-if="$route.name === 'reisetermin-view-releasepruefung'"
        :reiseterminObj="reiseterminObj"
      ></ReiseterminViewReleasepruefung>
    </div>
  </div>
</template>

<script>
import { getInBlankAngelegt } from '@/core/common/helpers/utils';
import ReleaseprozessIcons from '@/components/produkte/reisetermine/releaseprozess-icons.vue';
import ReiseterminViewDetails from '@/components/produkte/reisetermine/reisetermin-view-details.vue';
import ReiseterminViewReleasepruefung from '@/components/produkte/reisetermine/reisetermin-view-releasepruefung.vue';

export default {
  components: {
    ReleaseprozessIcons,
    ReiseterminViewDetails,
    ReiseterminViewReleasepruefung,
  },
  props: {
    reiseterminObj: Object,
    isInitialLoading: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    kontaktdatenlisteLink() {
      if (!this.reiseterminObj) {
        return null;
      }
      const startdatumYear = new Date(this.reiseterminObj.startdatum).getFullYear();
      return startdatumYear === 2022
        ? 'https://docs.google.com/spreadsheets/d/1jLZQswDFvOvXLT9qlg44QPP5tDp0mcvt5z0PYof_GG8/edit?usp=sharing'
        : startdatumYear === 2023
        ? 'https://docs.google.com/spreadsheets/d/1ISyLNU-nPPi_M7Yrtm_r55Q789Y_9xdpUlRXZpbW-5k/edit#gid=0'
        : startdatumYear === 2024
        ? 'https://docs.google.com/spreadsheets/d/1mf6SBNPv8NNBUulHYNA1XGyKcPxrp3nsRz7Nyx6Har0/edit#gid=0'
        : startdatumYear === 2025
        ? 'https://docs.google.com/spreadsheets/d/1OkrSdCu1SskISUsioOWzJhi1M4lCqhAtScsvcdFMrWo/edit?gid=0#gid=0'
        : null;
    },
  },
  methods: {
    getInBlankAngelegt,
    onAufgabeBearbeitet(response) {
      this.$emit('aufgabeBearbeitet', response);
    },
    getStateGerman(value) {
      return value !== null && value !== undefined ? this.$t('REISETERMINE.states.' + value) : value;
    },
  },
};
</script>

<style>
.tooltip {
  opacity: 1 !important;
}

.custom-tooltip > .tooltip-inner {
  max-width: none;
}
</style>
