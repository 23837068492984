<template>
  <div class="d-flex" style="gap: 8px" @mouseleave="resetHoverState">
    <div
      v-for="(fillPercent, i) in displayedRating"
      :key="'star' + i + '-' + fragenId"
      :id="'star' + i + '-' + fragenId"
      @click="onClickStar(i)"
      @mouseover="onHoverStar(i)"
      class="cursor"
    >
      <Star :fillPercent="fillPercent" />
      <b-tooltip :target="'star' + i + '-' + fragenId" v-if="currentLabel">
        {{ currentLabel }}
      </b-tooltip>
    </div>
  </div>
</template>
<script>
import Star from '@/components/common/star';
export default {
  components: {
    Star,
  },
  props: { value: { type: Number }, fragenId: { type: Number } },
  data() {
    return {
      hoverIndex: -1,
      ratingLabels: {
        1: 'Sehr unzufrieden',
        2: 'Unzufrieden',
        3: 'Verbesserungspotenzial',
        4: 'Zufrieden',
        5: 'Sehr zufrieden',
      },
    };
  },
  computed: {
    displayedRating() {
      // If hovering, show filled stars up to hover position
      if (this.hoverIndex >= 0) {
        return Array(5)
          .fill(0)
          .map((_, index) => (index <= this.hoverIndex ? 1 : 0));
      }

      // Otherwise show actual rating
      let localRating = this.value;
      const stars = Array(5).fill(0);
      for (const i in stars) {
        stars[i] = localRating >= 1 ? 1 : Math.round(localRating * 100) / 100;
        localRating = Math.max(localRating - 1, 0);
      }
      return stars;
    },
    currentLabel() {
      const activeIndex = this.hoverIndex >= 0 ? this.hoverIndex + 1 : this.value;
      return this.ratingLabels[activeIndex] || '';
    },
  },
  methods: {
    onClickStar(i) {
      this.$emit('input', i + 1);
    },
    onHoverStar(i) {
      this.hoverIndex = i;
    },
    resetHoverState() {
      this.hoverIndex = -1;
    },
  },
};
</script>
<style scoped>
.cursor {
  cursor: pointer;
}

.tooltip {
  opacity: 1 !important;
}
</style>
