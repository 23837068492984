import { format, differenceInDays } from 'date-fns';
import { formatDateWithoutHours } from '@/core/common/helpers/utils';
import { formatAndCalculateReleasedatum } from '@/core/common/helpers/utils';

export const zvcVerlinkungenFields = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reisekürzel',
    formatter: value => value?.substring(0, 6),
  },
  {
    tdClass: 'align-middle',
    key: 'reise.prioritaet',
    label: 'Priorität',
  },
  {
    tdClass: 'align-middle',
    key: 'startdatum',
    label: 'Startdatum',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    tdClass: 'align-middle',
    key: 'garantiert',
    label: 'Garantiert',
  },
  {
    tdClass: 'align-middle',
    key: 'garantiert',
    label: 'Garantiert',
  },
  {
    tdClass: 'align-middle',
    key: 'releasedatum',
    label: 'Tage bis Release',
    formatter: value => {
      if (value) {
        const releasedate = new Date(value);
        const tageBisRelease = differenceInDays(releasedate, new Date());
        const formattedReleasedate = format(releasedate, 'dd.MM.yyyy');
        return `${tageBisRelease} (${formattedReleasedate})`;
      }
      return '';
    },
  },
  {
    tdClass: 'align-middle',
    key: 'zimmerkontingenteWidget',
    label: 'Zimmerkontingente',
  },
  {
    tdClass: 'align-middle',
    key: 'paxWidget',
    label: 'PAX',
  },
  {
    tdClass: 'align-middle',
    key: 'nochErwartetePax',
    label: 'Noch erw. PAX',
  },
  {
    tdClass: 'align-middle',
    key: 'paxBisMax',
    label: 'PAX bis Max',
  },
  {
    tdClass: 'align-middle',
    key: 'flugverfuegbarkeiten',
    label: 'Flugverfügbarkeiten',
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const fomVerlinkungenFields = [
  {
    tdClass: 'align-middle',
    key: 'reise.reisekuerzel',
    label: 'Reisekürzel',
  },
  {
    key: 'actionsRTDetailUndFlugplanung',
    label: 'Links',
  },
  {
    tdClass: 'align-middle',
    key: 'reise.prioritaet',
    label: 'Priorität',
  },
  {
    tdClass: 'align-middle',
    key: 'abreisedatum',
    label: 'Abreisedatum',
    formatter: value => formatDateWithoutHours(value),
  },
  {
    tdClass: 'align-middle',
    key: 'startdatum',
    label: 'Startdatum',
    formatter: value => formatDateWithoutHours(value),
  },
  {
    tdClass: 'align-middle',
    key: 'paxWidget',
    label: 'PAX',
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.transferszenarioHin',
    label: 'TS Hin',
    formatter: value => value?.substring(0, 2),
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.transferszenarioRueck',
    label: 'TS Rück',
    formatter: value => value?.substring(0, 2),
  },
  {
    tdClass: 'align-middle',
    key: 'thMain',
    label: 'TH Langstrecke',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    tdClass: 'align-middle',
    key: 'thAlleAddOns',
    label: 'TH Inlandsflug',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    tdClass: 'align-middle',
    key: 'thGesamt',
    label: 'TH Gesamt',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const releaseprozessVerlinkungenFields = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reisekürzel',
    formatter: value => value?.substring(0, 6),
  },
  { tdClass: 'align-middle', key: 'state', label: 'Status' },
  {
    tdClass: 'align-middle',
    key: 'startdatum',
    label: 'Startdatum',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    tdClass: 'align-middle',
    label: 'Releasefristen',
    key: 'releasefrist',
    formatter: (value, key, item) => {
      // schauen ob releasefristen vorhanden sind
      if (item.metadata?.releasefrist?.length > 0) {
        return (
          item.metadata.releasefrist
            // geht über jede Releasefrist und jedes element wird als releasefrist bezeichnet
            .map(releasefrist => {
              // berechnet das datum für jede releasefrist
              const releasedatum = formatAndCalculateReleasedatum(item.startdatum, releasefrist);
              if (item.metadata?.releasefrist?.length > 1 && releasefrist === item.blankReleasefrist) {
                return `<b>${releasefrist} (${releasedatum})</b>`;
              }
              return `${releasefrist} (${releasedatum})`;
            })
            .join(' / ')
        );
      } else {
        return '-';
      }
    },
  },
  {
    tdClass: 'align-middle',
    key: 'unterlagenversandDatum',
    label: 'Versand',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    tdClass: 'align-middle',
    key: 'vorgangReleaseprozess.teilnehmerErledigt',
    label: 'TD',
  },
  {
    tdClass: 'align-middle',
    key: 'vorgangReleaseprozess.reiseleistungListenversandErledigt',
    label: 'GL',
  },
  {
    tdClass: 'align-middle',
    key: 'vorgangReleaseprozess.fluegePassend',
    label: 'F',
  },
  {
    tdClass: 'align-middle',
    key: 'vorgangReleaseprozess.transferplanungErledigt',
    label: 'TP',
  },
  {
    tdClass: 'align-middle',
    key: 'reiseunterlagenGeprueft',
    label: 'RTE',
  },
  {
    tdClass: 'align-middle',
    key: 'transferplanungGeprueft',
    label: 'FTP',
  },
  {
    tdClass: 'align-middle',
    key: 'garantiert',
    label: 'Gar.',
  },
  {
    tdClass: 'align-middle',
    key: 'paxWidget',
    label: 'PAX',
  },
  { tdClass: 'align-middle', key: 'metadata.isKritischeDestination', label: 'Krit. Dest.' },
  {
    tdClass: 'align-middle',
    key: 'commentCount',
    label: 'Kommentare',
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const basicVerlinkungenFields = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reiseterminkürzel',
  },
  {
    key: 'actionsRTDetailUndFlugplanung',
    label: 'Links',
  },
  {
    tdClass: 'align-middle',
    key: 'startdatum',
    label: 'Startdatum',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    tdClass: 'align-middle',
    key: 'reisezeitraum',
  },
  {
    tdClass: 'align-middle',
    key: 'reisedauer',
    label: 'Reisedauer',
    formatter: value => (value ? value + ' Tage' : '-'),
  },
  {
    tdClass: 'align-middle',
    key: 'releasedatum',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  { tdClass: 'align-middle', key: 'paxWidget' },
  {
    tdClass: 'align-middle',
    key: 'abgesagt',
    label: 'Abgesagt',
  },
  {
    tdClass: 'align-middle',
    key: 'garantiert',
    label: 'Gar.',
  },
  {
    tdClass: 'align-middle',
    key: 'vorzeitigGarantiert',
    label: 'Vorz. gar.',
  },
  {
    tdClass: 'align-middle',
    key: 'stopSale',
    label: 'Stop Sale',
    thStyle: { whiteSpace: 'nowrap' },
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.overnightOutbound',
    label: 'OB',
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.overnightInbound',
    label: 'IB',
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.hotelstartVorLandung',
    label: 'Hotelstart v. Landung',
    thStyle: { whiteSpace: 'nowrap' },
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.rueckflugNachHotelende',
    label: 'Rückflug n. Hotelende',
    thStyle: { whiteSpace: 'nowrap' },
  },
  {
    tdClass: 'align-middle',
    key: 'ziellandZielUndAblugflughafen',
    label: 'Zielland Ziel-/Abfh.',
    thStyle: { whiteSpace: 'nowrap' },
  },
  {
    tdClass: 'align-middle',
    key: 'TSHinUndRueck',
    label: 'TS Hin/Rück',
    thStyle: { whiteSpace: 'nowrap' },
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const fieldsGruppeneinkaufPruefen = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reiseterminkürzel',
  },
  {
    key: 'metadata.transferszenarioHin',
    label: 'Transferszenario Hin',
    formatter: value => (value ? value.substring(0, 2) : '-'),
  },
  {
    key: 'metadata.transferszenarioRueck',
    label: 'Transferszenario Rück',
    formatter: value => (value ? value.substring(0, 2) : '-'),
  },
  {
    key: 'metadata.vkTransferkostenHin',
    label: 'VK Transferkosten Hin',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'metadata.vkTransferkostenRueck',
    label: 'VK Transferkosten Hin',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'actionsRTDetailUndFlugplanung',
    label: 'Links',
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const fieldsAnlageHotelstamm = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reiseterminkürzel',
  },
  {
    key: 'metadata.hotelnaechte',
    label: 'Hotelnächte',
    formatter: value => value ?? '-',
  },
  {
    key: 'metadata.minPax',
    label: 'Min Pax',
    formatter: value => value ?? '-',
  },
  {
    key: 'metadata.maxPax',
    label: 'Max Pax',
    formatter: value => value ?? '-',
  },
  {
    key: 'metadata.releasefrist',
    label: 'Releasefrist',
    formatter: value => value ?? '-',
  },
  {
    key: 'metadata.hotelstartVorLandung',
    label: 'Hotelstart v. Landung',
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const fieldsAnlageFlugstamm = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reiseterminkürzel',
  },
  {
    key: 'abreisedatum',
    label: 'Abreise',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    key: 'hotelendUndRueckflugdatum',
    label: 'Rückflug - datum',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    key: 'metadata.transferszenarioHin',
    label: 'TF Hin',
    formatter: value => (value ? value.substring(0, 2) : '-'),
  },
  {
    key: 'metadata.transferszenarioRueck',
    label: 'TF Rück',
    formatter: value => (value ? value.substring(0, 2) : '-'),
  },
  {
    key: 'metadata.vkTransferkostenHin',
    label: 'VK TF Hin',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'metadata.vkTransferkostenRueck',
    label: 'VK TF Rück',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'metadata.skrFlugangebot',
    label: 'SKR Flugangebot',
    formatter: value => (value ? value + ' €' : '-'),
  },
  {
    key: 'ziellandZielUndAblugflughafen',
    label: 'ZL Zielflughafen /Abflughafen',
    formatter: value => value ?? '-',
  },
  { key: 'metadata.overnightOutbound', label: 'OB', formatter: value => value ?? '-' },
  { key: 'metadata.overnightInbound', label: 'IB', formatter: value => value ?? '-' },
  {
    key: 'metadata.hotelstartVorLandung',
    label: 'Hotelstart v. Landung',
    formatter: value => value ?? '-',
  },
  {
    key: 'metadata.rueckflugNachHotelende',
    label: 'Rückflug n. Hotelende',
    formatter: value => value ?? '-',
  },

  {
    key: 'metadata.fruehesteAnkunft',
    label: 'Früh. Ankuft Hin',
    formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
  },
  {
    key: 'metadata.spaetesteAnkunft',
    label: 'Spät. Ankuft Hin',
    formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
  },

  {
    key: 'metadata.fruehesterAbflug',
    label: 'Früh. Abflug Rück',
    formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
  },
  {
    key: 'metadata.spaetesterAbflug',
    label: 'Spät. Abflug Rück',
    formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
  },
  {
    key: 'metadata.vkVornachtDzProPerson',
    label: 'VK kalk. Vornacht DZ p.P.',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'thLangstrecke',
    label: 'TH Langstrecke',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'inlandsflugEinkauf',
    label: 'INFL: Einkauf',
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const fieldsOptionsfristErreicht = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reiseterminkürzel',
  },
  {
    key: 'actionsRTDetailUndFlugplanung',
    label: 'Links',
  },
  { key: 'state', label: 'Status' },
  { key: 'flugeinkaufStatusgruppe', label: 'Statusgruppe' },
  { key: 'flugeinkaufEinkaufsart', label: 'Einkaufsart' },
  { key: 'paxWidget', label: 'PAX' },
  { key: 'flugeinkauf.gruppePnr', label: 'Gruppe PNR' },
  { key: 'flugeinkauf.flugnummerGruppeHin', label: 'Flugnr. Gruppe Hin' },
  { key: 'flugeinkauf.flugnummerGruppeRueck', label: 'Flugnr. Gruppe Rück' },
  { key: 'flugeinkauf.eingekauftePax', label: 'Eingekaufte Pax' },
  { key: 'flugeinkaufGruppeFlughafen', label: 'Gruppe FH' },
  { key: 'flugeinkauf.ibClass', label: 'IB Class' },
  { key: 'flugeinkauf.obClass', label: 'OB Class' },
  {
    key: 'flugeinkauf.hauptstreckePreisNetto',
    label: 'Preis Hauptstrecke Netto',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  { key: 'flugeinkauf.inlandsflugPreis', label: 'Preis Inlandsflug' },
  { key: 'flugeinkauf.taxesFlug', label: 'Taxes Flug' },
  {
    key: 'flugeinkauf.gesamtpreisFlugeinkauf',
    label: 'Gesamtpreis Gruppe',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'thLangstrecke',
    label: 'TH Langstrecke',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'thGesamt',
    label: 'TH Gesamt',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    key: 'flugeinkauf.optionsfrist',
    label: 'Optionsfrist',
    formatter: value => (value ? value + ' Tage' : '-'),
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const fieldsStopsaleSetzen = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reiseterminkürzel',
  },
  {
    tdClass: 'align-middle',
    key: 'abreisedatum',
    label: 'Abreisedatum',
    formatter: value => formatDateWithoutHours(value),
  },
  {
    tdClass: 'align-middle',
    key: 'startdatum',
    label: 'Startdatum',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    tdClass: 'align-middle',
    key: 'paxWidget',
    label: 'PAX',
  },
  {
    tdClass: 'align-middle',
    key: 'abgesagt',
    label: 'Abgesagt',
  },
  {
    tdClass: 'align-middle',
    key: 'garantiert',
    label: 'Garantiert',
  },
  {
    tdClass: 'align-middle',
    key: 'stopSale',
    label: 'Stop Sale',
    thStyle: { whiteSpace: 'nowrap' },
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.hotelstartVorLandung',
    label: 'Hotelstart v. Landung',
    thStyle: { whiteSpace: 'nowrap' },
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.rueckflugNachHotelende',
    label: 'Rückflug n. Hotelende',
    thStyle: { whiteSpace: 'nowrap' },
  },
  {
    tdClass: 'align-middle',
    key: 'ziellandZielUndAblugflughafen',
    label: 'Zielland Ziel-/Abfh.',
    thStyle: { whiteSpace: 'nowrap' },
  },
  {
    tdClass: 'align-middle',
    key: 'zimmerkontingenteWidget',
    label: 'Zimmerkontingente',
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const teilnehmerdokumenteVerlinkungenFields = [
  {
    tdClass: 'align-middle',
    key: 'reiseterminkuerzel',
    label: 'Reisekürzel',
    formatter: value => value?.substring(0, 6),
  },
  { tdClass: 'align-middle', key: 'state', label: 'Status' },
  {
    tdClass: 'align-middle',
    key: 'startdatum',
    label: 'Startdatum',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    tdClass: 'align-middle',
    key: 'releasedatum',
    label: 'Releasedatum',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    tdClass: 'align-middle',
    label: 'Releasefristen',
    key: 'releasefrist',
    formatter: (value, key, item) => {
      // schauen ob releasefristen vorhanden sind
      if (item.metadata?.releasefrist?.length > 0) {
        return (
          item.metadata.releasefrist
            // geht über jede Releasefrist und jedes element wird als releasefrist bezeichnet
            .map(releasefrist => {
              // berechnet das datum für jede releasefrist
              const releasedatum = formatAndCalculateReleasedatum(item.startdatum, releasefrist);
              return `${releasefrist} (${releasedatum})`;
            })
            .join(' / ')
        );
      } else {
        return '-';
      }
    },
  },
  {
    tdClass: 'align-middle',
    key: 'unterlagenversandDatum',
    label: 'Versand',
    formatter: value => {
      return formatDateWithoutHours(value);
    },
  },
  {
    tdClass: 'align-middle',
    key: 'vorgangReleaseprozess.teilnehmerErledigt',
    label: 'TD',
  },
  {
    tdClass: 'align-middle',
    key: 'vorgangReleaseprozess.reiseleistungListenversandErledigt',
    label: 'GL',
  },
  {
    tdClass: 'align-middle',
    key: 'vorgangReleaseprozess.fluegePassend',
    label: 'F',
  },
  {
    tdClass: 'align-middle',
    key: 'vorgangReleaseprozess.transferplanungErledigt',
    label: 'TP',
  },
  {
    tdClass: 'align-middle',
    key: 'reiseunterlagenGeprueft',
    label: 'RTE',
  },
  {
    tdClass: 'align-middle',
    key: 'transferplanungGeprueft',
    label: 'FTP',
  },
  {
    tdClass: 'align-middle',
    key: 'garantiert',
    label: 'Gar.',
  },
  {
    tdClass: 'align-middle',
    key: 'paxWidget',
    label: 'PAX',
  },
  { tdClass: 'align-middle', key: 'metadata.isKritischeDestination', label: 'Krit. Dest.' },
  { tdClass: 'align-middle', key: 'benoetigteDokumente', label: 'Benötigte Dokumente' },
  {
    tdClass: 'align-middle',
    key: 'commentCount',
    label: 'Kommentare',
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];

export const kurzesTktlFields = [
  {
    tdClass: 'align-middle',
    key: 'reise.reisekuerzel',
    label: 'Reisekürzel',
  },
  {
    key: 'actionsRTDetailUndFlugplanung',
    label: 'Links',
  },
  {
    tdClass: 'align-middle',
    key: 'reise.prioritaet',
    label: 'Priorität',
  },
  {
    tdClass: 'align-middle',
    key: 'abreisedatum',
    label: 'Abreisedatum',
    formatter: value => formatDateWithoutHours(value),
  },
  {
    tdClass: 'align-middle',
    key: 'startdatum',
    label: 'Startdatum',
    formatter: value => formatDateWithoutHours(value),
  },
  {
    tdClass: 'align-middle',
    key: 'paxWidget',
    label: 'PAX',
  },
  {
    tdClass: 'align-middle',
    key: 'flugeinkauf.einkaufsart',
    label: 'Einkaufsart',
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.transferszenarioHin',
    label: 'TS Hin',
    formatter: value => value?.substring(0, 2),
  },
  {
    tdClass: 'align-middle',
    key: 'metadata.transferszenarioRueck',
    label: 'TS Rück',
    formatter: value => value?.substring(0, 2),
  },
  {
    tdClass: 'align-middle',
    key: 'thMain',
    label: 'TH Langstrecke',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    tdClass: 'align-middle',
    key: 'thAlleAddOns',
    label: 'TH Inlandsflug',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  {
    tdClass: 'align-middle',
    key: 'thGesamt',
    label: 'TH Gesamt',
    formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
  },
  { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
];
