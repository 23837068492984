<template>
  <div class="card card-custom gutter-b">
    <div class="card-header card-header-tabs-line">
      <div class="card-title">
        <ActionButton
          class="mr-3"
          v-b-toggle.sheetdaten-collapse
          title="Ausklappen"
          :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
          @click="toggleCollapse"
        />
        <h3 class="card-label">{{ correctTitle }}</h3>

        <div class="card-toolbar"></div>
      </div>
      <ul class="nav nav-tabs nav-bold nav-tabs-line">
        <li class="nav-item">
          <router-link
            :to="{
              path: '',
              query: { ...this.$route.query, sheetdatatab: 'flugdaten' },
            }"
            v-slot="{ href }"
          >
            <a class="nav-link" :class="{ active: activeTabFromRouter === 'flugdaten' }" :href="href">
              <span class="nav-icon"><i class="fas fa-plane"></i></span>
              <span class="nav-text">Flugdaten</span>
            </a>
          </router-link>
        </li>
        <li class="nav-item" v-if="showAbwicklungssheet">
          <router-link
            :to="{
              path: '',
              query: { ...this.$route.query, sheetdatatab: 'abwicklungssheet' },
            }"
            v-slot="{ href }"
          >
            <a class="nav-link" :class="{ active: activeTabFromRouter === 'abwicklungssheet' }" :href="href">
              <span class="nav-icon"><i class="fas fa-list"></i></span>
              <span class="nav-text">Abwicklungssheet</span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
    <b-collapse visible id="sheetdaten-collapse">
      <div class="card-body">
        <div
          v-show="activeTabFromRouter === 'abwicklungssheet'"
          :class="{ show: activeTabFromRouter === 'abwicklungssheet' }"
          class="tab-pane active"
          v-if="showAbwicklungssheet"
        >
          <div
            v-if="sheetdata?.abwicklungssheet"
            class="d-flex align-items-baseline py-2 overflow-auto scroll scroll-pull"
            data-scroll="true"
            data-wheel-propagation="true"
          >
            <div
              class="mb-2 flex-shrink-0"
              v-for="(value, key) in sheetdata.abwicklungssheet"
              :key="key + value"
            >
              <div class="mr-6 d-flex flex-column align-items-baseline" v-if="value !== '' && value !== '-'">
                <div class="font-weight-bold mb-4">
                  {{ key }}
                </div>
                <span
                  class="rounded p-3 bg-light-primary text-primary"
                  style="max-width: 400px; white-space: pre-line"
                >
                  {{ value }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="activeTabFromRouter === 'flugdaten'"
          :class="{ show: activeTabFromRouter === 'flugdaten' }"
          class="tab-pane active"
        >
          <ReiseterminCardFlugdataReleasepruefung
            :reiseterminObj="reiseterminObj"
          ></ReiseterminCardFlugdataReleasepruefung>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import axios from 'axios';
import ActionButton from '@/components/common/action-button.vue';
import ReiseterminCardFlugdataReleasepruefung from '@/components/produkte/reisetermine/reisetermin-card-flugdata-releasepruefung.vue';

export default {
  props: {
    reiseterminObj: { type: Object },
  },
  components: {
    ActionButton,
    ReiseterminCardFlugdataReleasepruefung,
  },
  data() {
    return {
      sheetdata: null,
      currentFullPath: this.$route.fullPath,
      isCollapseOpen: true,
    };
  },
  watch: {
    reiseterminObj(newReiseterminObj) {
      if (newReiseterminObj) {
        this.loadData();
      }
    },
    $route(to) {
      this.currentFullPath = to.fullPath;
    },
  },
  computed: {
    showAbwicklungssheet() {
      if (this.currentFullPath.includes('releasepruefung')) {
        return true;
      }
      return false;
    },
    activeTabFromRouter() {
      return (
        this.$route?.query?.sheetdatatab ||
        (this.$route?.fullPath.includes('releasepruefung') ? 'abwicklungssheet' : 'flugdaten')
      );
    },
    correctTitle() {
      return this.activeTabFromRouter === 'flugdaten' ? 'Flugdaten' : 'Abwicklungssheet';
    },
  },
  methods: {
    loadData() {
      axios
        .get(
          'https://api.skr.de/get-abwicklungssheet?code=IncCkOoJiAo3o1EsDBYlT5ifZUv7z8JqC2tYk7zCpuwFAzFuF5sjpA==&reiseterminkuerzel=' +
            this.reiseterminObj.reiseterminkuerzel
        )
        .then(response => {
          const filterOutKeys = ['Region', 'Status', 'Reisekürzel', 'Transferzeiten TUKA'];
          const filteredAbwicklungssheet = Object.keys(response.data.data)
            .filter(key => !filterOutKeys.includes(key))
            .reduce((obj, key) => {
              obj[key] = response.data.data[key];
              return obj;
            }, {});
          this.sheetdata = {
            abwicklungssheet: filteredAbwicklungssheet,
          };
        });
    },
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
  },
  mounted() {
    if (this.reiseterminObj) {
      this.loadData();
    }
  },
};
</script>
