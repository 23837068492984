<template>
  <div class="row m-0">
    <div class="col-md-12 gutter-b">
      <div class="card card-custom card-fit card-border">
        <div class="card-body">
          <b-table
            :items="items"
            :fields="fields"
            show-empty
            :empty-text="isLoading ? 'Laden...' : $t('COMMON.EMPTYTEXT', { name: 'Vorgänge' })"
            :busy.sync="isLoading"
          >
            <template #cell(reisezeitraum)="{ item, index }">
              {{ item.reisedatum | date }} - {{ item.enddatum | date }}
            </template>
            <template #cell(stateSlot)="{ item, index }">
              <VorgangStateWidget :state="item.blankState" />
            </template>
            <template #cell(kunde)="{ item, index }">
              <template v-if="item.kunde">
                <kunden-name-popover :kunde="item.kunde.master"></kunden-name-popover>
              </template>
              <div v-else>-</div>
            </template>
            <template #cell(npx)="{ item, index }">
              <b-badge variant="secondary">{{ item.npx }}</b-badge>
            </template>
            <template #cell(zimmer)="{ item, index }">
              <template>{{ summarizeZimmer(item.zimmer) }}</template>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as odataService from '@/core/common/services/odata.service';
import apiService from '@/core/common/services/api.service';

import KundenNamePopover from '@/components/kunden/kunden-name-popover.vue';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import buildQuery from 'odata-query';

const moment = require('moment');

export default {
  name: 'ReiseterminTableDetails',
  components: { VorgangStateWidget, KundenNamePopover },
  props: {
    reiseterminObject: { type: Object },
  },
  data() {
    return {
      vorgaenge: [],
      isLoading: true,
      items: null,
      fields: [
        { key: 'id', label: this.$t('COMMON.BNR'), tdClass: 'cw-5', sortable: true },
        {
          key: 'reisezeitraum',
          label: this.$t('REISEN.COMMON.TRAVELPERIOD'),
          tdClass: 'cw-20',
          sortable: true,
        },
        { key: 'kunde', label: this.$t('KUNDEN.KUNDE'), tdClass: 'cw-20' },
        {
          key: 'zimmer',
          label: this.$t('VORGAENGE.ZIMMERTYP'),
          tdClass: 'cw-20',
        },

        { key: 'npx', label: this.$t('COMMON.PAX'), tdClass: 'cw-5' },
        {
          key: 'stateSlot',
          label: this.$t('REISEN.COMMON.STATE'),
          sortDirection: 'asc',
          tdClass: 'cw-20',
          sortable: true,
        },
      ],
      expand: {
        hotelleistungen: {
          filter: {
            isDeleted: false,
          },
          expand: {
            Reisetermin: {
              filter: {
                isDeleted: false,
              },
              expand: { Reise: {}, pax: {}, gueltigeKonfiguration: {} },
            },
          },
        },
        agentur: { select: ['id'] },
        kunde: {
          expand: {
            master: {
              expand: { kunden: {} },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    reisezeitraum() {
      if (!this.reiseterminObject) return '-';
      const reisedauer = this.reiseterminObject?.reisedauer ? this.reiseterminObject?.reisedauer - 1 : 0;
      const momentUntilDate = moment(this.reiseterminObject.abreisedatum).add(reisedauer, 'days');
      return this.reiseterminObject?.abreisedatum
        ? `${this.$options.filters.date(this.reiseterminObject.abreisedatum)} -  ${this.$options.filters.date(
            momentUntilDate
          )}`
        : '-';
    },
  },
  methods: {
    getData() {
      const query = {
        filter: {
          id: this.reiseterminObject.id,
        },
        expand: {
          reise: {
            select: ['reisekuerzel', 'region', 'id'],
          },
          hotelleistungen: {
            select: ['id'],
            expand: {
              vorgang: {
                expand: {
                  kunde: {
                    select: ['email', 'geburtsdatum', 'id'],
                    expand: {
                      master: {
                        select: [
                          'kundennummer',
                          'anrede',
                          'titel',
                          'vorname',
                          'nachname',
                          'email',
                          'geburtsdatum',
                          'strasse',
                          'postleitzahl',
                          'ort',
                          'land',
                          'telefonPrivat',
                          'telefonGeschaeftlich',
                          'telefonMobil',
                          'id',
                        ],
                      },
                    },
                  },
                },
              },
            },
          },
        },
        select: ['id'],
      };
      const builtQuery = buildQuery(query);
      apiService
        .get('reisetermin' + builtQuery)
        .then(response => {
          const { hotelleistungen } = response.data.result.value[0];
          const vorgaenge = hotelleistungen.map(hl => hl.vorgang);
          this.items = vorgaenge;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    summarizeZimmer(zimmer) {
      // Summarizes the roomtypes
      // Input [EZ,EZ,DZ,EZ,TZ]
      // Output 3xEZ,1xEZ,1xTZ
      return (
        Object.entries(
          zimmer.reduce((acc, cur) => {
            acc[cur] = acc[cur] + 1 || 1;
            return acc;
          }, {})
        )
          .map(a => a[1] + 'x ' + a[0])
          .join(',') || '-'
      );
    },
    getVorgaenge(id) {
      const filter = {
        hotelleistungen: {
          any: {
            isDeleted: false,
            reiseterminId: parseInt(id),
          },
        },
      };
      return odataService
        .getVorgang({ expand: this.expand, filter })
        .then(response => {
          this.vorgaenge = response.data;
          this.isLoadingVorgaenge = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async onUpdateSingleItem(index, id) {
      const newVorgang = await this.getVorgangById(id);
      this.$set(this.vorgaenge, index, newVorgang);
    },
    async getVorgangById(id) {
      this.isLoading = true;
      const filter = { id: id };
      return odataService
        .getVorgang({ expand: this.expand, filter })
        .then(response => {
          return response.data[0];
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    sortCompare(aRow, bRow, key) {
      if (key === 'stateSlot') {
        const statesOrder = {
          Buchung: 1,
          BuchungReleased: 2,
          Option: 3,
          Anfrage: 4,
          Vormerkung: 5,
          Warteliste: 6,
          StornierteBuchung: 7,
          StornierteOption: 8,
          StornierteAnfrage: 9,
          StornierteVormerkung: 10,
          StornierteWarteliste: 11,
          Unbekannt: 12,
        };
        const a = statesOrder[aRow.blankState] || 0;
        const b = statesOrder[bRow.blankState] || 0;
        return a < b ? -1 : a > b ? 1 : 0;
      } else if (key === 'reisedatum' || key === 'reisezeitraum') {
        return new Date(bRow.reisedatum) - new Date(aRow.reisedatum);
      }
    },
  },
};
</script>

<style lang="scss">
.more-btn {
  cursor: pointer;
}
table#vorgaengeTable > thead > tr > th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
