export const terminplanungHeaderRow = (language, type = 'standard') => {
  const headers = {
    standard: {
      de: [
        'Zusatztermin',
        'Reisekürzel',
        'Abreise',
        'VT Hin',
        'Status',
        'In Blank angelegt',
        'Startdatum',
        'Hotelnächte',
        'Reisedauer',
        'TH Langstrecke',
        'TH Inlandsflug',
        'OB',
        'IB',
        'Flight Arrival',
        'Strecke',
        'Rückflugdatum',
        'VT Rück',
        'Ankunft DE',
        'Erw. Pax',
        'Erw. Termindurchführungsquote',
      ],
      en: [
        'Additional date',
        'Tour ID/Code',
        'Departure Germany',
        'Traffic day towards',
        'Status',
        'Created in Blank',
        'Start Destination',
        'Hotel Nights',
        'Trip Duration',
        'TH Long-haul',
        'TH Domestic Flight',
        'OB',
        'IB',
        'Flight Arrival',
        'Route',
        'Return Flight Date',
        'Departure Destination',
        'Arrival Germany',
        'Expected Pax',
        'Expected Schedule Completion Rate',
      ],
    },
    extern: {
      de: [
        'Reisekürzel',
        'Abreise',
        'OB',
        'Startdatum',
        'Hotelnächte',
        'Rückflugdatum',
        'IB',
        'Ankunft DE',
        'Status',
      ],
      en: [
        'Tour ID/Code',
        'Departure Germany',
        'OB',
        'Start Destination',
        'Hotel Nights',
        'Departure Destination',
        'IB',
        'Arrival Germany',
        'Status',
      ],
    },
  };

  return headers[type]?.[language] || headers[type].en;
};

export const terminplanungSelectedReiseterminVariables = {
  standard: [
    'reiseterminkuerzel',
    'startdatum',
    'istZusatztermin',
    'garantiert',
    'abreisedatum',
    'hotelendUndRueckflugdatum',
    'state',
    'verkehrstagHin',
    'verkehrstagRueck',
    'thLangstrecke',
    'thInlandsflug',
    'reiseterminSyncState',
    'reisedauer',
    'enddatum',
  ],
  extern: [
    'reiseterminkuerzel',
    'abreisedatum',
    'startdatum',
    'hotelendUndRueckflugdatum',
    'enddatum',
    'state',
  ],
};

export const terminplanungExpandConfig = {
  standard: {
    metadata: {
      expand: { ziellandAbflughaefen: {}, ziellandZielflughaefen: {} },
      select: [
        'overnightInbound',
        'overnightOutbound',
        'fruehesteAnkunft',
        'spaetesteAnkunft',
        'fruehesterAbflug',
        'spaetesterAbflug',
        'transferszenarioHin',
        'transferszenarioRueck',
        'hotelnaechte',
        'erwartetePax',
        'erwarteteTermindurchfuehrungsquote',
      ],
    },
    pax: { select: ['ist'] },
  },
  extern: {
    metadata: {
      select: ['overnightOutbound', 'hotelnaechte', 'overnightInbound'],
    },
  },
};

export const flugplanungHeaderRow = [
  'Zusatztermin',
  'Inlandsflug',
  'Reise',
  'Garantiert',
  'Ist PAX',
  'Erw. PAx',
  'Abreise',
  'Rückflugdatum',
  'ZL Zielflughafen',
  'ZL Abflughafen',
  'Abflug INFL',
  'Strecke INFL',
  'Status',
  'Status Gruppe',
  'Einkaufsart',
  'Stop Sale',
  'Main/INFL',
  'OB',
  'IB',
  'Startdatum',
  'GPR PNR',
  'Hinflug Flugnr. GPR/INFL Flugnr.',
  'Hinflug VT',
  'Früh. Ankunft',
  'Spät Ankunft',
  'Rückflug Flugnr. GRP',
  'Rückflug VT',
  'Früh. Abflug',
  'Spät Abflug',
  'Ankunft DE',
  'TF Hin',
  'TF Rück',
  'Preis Netto',
  '€-Summe aller INFL',
  'Taxes',
  'Gesamtpreis GRP',
  'TH Langstrecke',
  'TH Inlandsflug',
  'TH Max Langstrecke',
  'TH Max Inlandsflug',
  'Ticketdatum',
  'Optionsdatum',
];

export const flugplanungSelectedReiseterminVariables = [
  'reiseterminkuerzel',
  'startdatum',
  'optionsdatumFlugeinkauf',
  'istZusatztermin',
  'garantiert',
  'abreisedatum',
  'hotelendUndRueckflugdatum',
  'state',
  'stopSale',
  'verkehrstagHin',
  'verkehrstagRueck',
  'enddatum',
  'thLangstrecke',
  'thInlandsflug',
  'ticketfristdatumFlugeinkauf',
];

export const flugplanungExpandConfig = {
  metadata: {
    expand: { inlandsfluege: {}, ziellandAbflughaefen: {}, ziellandZielflughaefen: {} },
    select: [
      'overnightInbound',
      'overnightOutbound',
      'fruehesteAnkunft',
      'spaetesteAnkunft',
      'fruehesterAbflug',
      'spaetesterAbflug',
      'transferszenarioHin',
      'transferszenarioRueck',
      'inlandsfluegeEinkauf',
      'restriktionenHinRollover',
      'restriktionenRueckRollover',
      'kalkPax',
      'erwartetePax',
    ],
  },
  pax: { select: ['ist', 'min', 'max', 'ist', 'opt', 'anf', 'war', 'vor'] },
  flugeinkauf: {
    select: [
      'statusgruppe',
      'einkaufsart',
      'gruppePnr',
      'eingekauftePax',
      'mindestabnahmeAirline',
      'gruppeFlughafenIataCode',
      'flugnummerGruppeHin',
      'flugnummerGruppeRueck',
      'obClass',
      'ibClass',
      'optionsfrist',
      'ticketfrist',
      'hauptstreckePreisNetto',
      'inlandsflugPreis',
      'taxesFlug',
      'gesamtpreisFlugeinkauf',
    ],
  },
  gueltigerSaisonpreis: {},
};

export const flugstammdatenHeaderRow = [
  'Reise',
  'Abreise',
  'Rückflugdatum',
  'Früh. Ankunft',
  'Spät. Ankunft',
  'Früh. Abflug',
  'Spät. Abflug',
  'TS Hin',
  'TS Rück',
  'VK kalk. Vornacht DZ p.P.',
  'TH Langstrecke',
  'Flinkok ZK',
  'Flinkok ZTV',
];

export const flugstammdatenSelectedReiseterminVariables = [
  'reiseterminkuerzel',
  'abreisedatum',
  'hotelendUndRueckflugdatum',
  'thLangstrecke',
  'flinkokZx',
  'flinkokZtv',
  'startdatum',
];

export const flugstammdatenExpandConfig = {
  metadata: {
    select: [
      'fruehesteAnkunft',
      'spaetesteAnkunft',
      'fruehesterAbflug',
      'spaetesterAbflug',
      'transferszenarioHin',
      'transferszenarioRueck',
      'vkVornachtDzProPerson',
      'restriktionenHinRollover',
      'restriktionenRueckRollover',
    ],
  },
};
