<template>
  <div v-if="isActiveUmfrageForCurrentUserDepartment && !alreadyAnsweredActiveUmfrage">
    <div v-if="!showModal" class="umfragen-widget bg-warning" @click="toggleShow">
      <h2 class="mb-0">?</h2>
    </div>
    <b-modal
      id="umfragen-widget-modal"
      v-model="showModal"
      title="Dein Feedback zu den neuen Funktionen im Manager"
    >
      <b-overlay :show="isLoading">
        <div style="display: flex; gap: 24px; flex-direction: column">
          <h3 v-if="activeUmfrage?.ueberschrift">{{ activeUmfrage?.ueberschrift }}</h3>
          <div class="pl-4 d-flex flex-column" style="gap: 12px">
            <b-form-group v-for="(frage, id) in fragen" :key="'frage-' + frage.frage">
              <template #label>
                <span style="font-size: 14px">{{ frage.frage }}</span>
              </template>
              <StarRatingInput v-model="frage.antwort" :fragenId="id" />
            </b-form-group>
            <b-form-group>
              <template #label>
                <span style="font-size: 14px">Was würdest du anders machen?</span>
              </template>
              <b-form-input
                size="lg"
                v-model="kommentar"
                :state="anyAntwortSmallerThanFourStars && kommentar == null ? false : null"
                placeholder="Schreibe einen Kommentar..."
              />
            </b-form-group>
          </div>
        </div>
      </b-overlay>
      <template #modal-footer="{ hide }" class="pl-1">
        <b-button
          class="mr-4"
          :disabled="disableSichernButton"
          :variant="disableSichernButton ? 'secondary' : 'success'"
          @click="saveAntwort(hide)"
        >
          Senden
        </b-button>
        <b-button @click="hide">Abbrechen</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import StarRatingInput from '@/components/common/star-rating-input.vue';
import { SAVE_ANTWORT } from '@/core/umfragen/umfragen.module';

export default {
  components: {
    StarRatingInput,
  },
  data() {
    return {
      isLoading: false,
      showModal: true,
      fragen: [
        { frage: 'Wie zufrieden bist du insgesamt mit den neuen Funktionen?', antwort: 0 },
        { frage: 'Wie einfach und angenehm findest du es, die neuen Funktionen zu nutzen?', antwort: 0 },
        {
          frage: 'Wie zufrieden bist du mit dem Design und der Übersichtlichkeit der Benutzeroberfläche?',
          antwort: 0,
        },
      ],
      kommentar: null,
    };
  },
  computed: {
    ...mapGetters(['currentUserId', 'getActiveUmfrageAntwortenUserIds']),
    ...mapState({
      users: state => state.users.users,
      activeUmfragen: state => state.umfragen.activeUmfragen,
    }),
    getUserDepartment() {
      if (this.users?.length === 0) return null;
      if (!this.currentUserId) return null;
      return this.users.find(user => user.id === this.currentUserId)?.department;
    },
    activeUmfrage() {
      if (this.activeUmfragen == null) return null;
      return this.activeUmfragen.find(u => u.team == this.getUserDepartment);
    },
    isActiveUmfrageForCurrentUserDepartment() {
      if (!this.activeUmfragen) return false;
      if (this.getUserDepartment === null) return false;
      return this.activeUmfragen.some(u => u.team == this.getUserDepartment);
    },
    disableSichernButton() {
      return (
        this.fragen.some(frage => frage.antwort === 0) ||
        (this.anyAntwortSmallerThanFourStars && this.kommentar == null)
      );
    },
    alreadyAnsweredActiveUmfrage() {
      if (!this.currentUserId) return false;
      return this.getActiveUmfrageAntwortenUserIds.includes(this.currentUserId);
    },
    anyAntwortSmallerThanFourStars() {
      if (this.fragen.some(frage => frage.antwort === 0)) {
        return null;
      }
      return this.fragen.some(frage => frage.antwort > 0 && frage.antwort < 4);
    },
  },
  methods: {
    toggleShow() {
      this.showModal = !this.showModal;
    },
    saveAntwort(hide) {
      this.isLoading = true;
      this.$store
        .dispatch(SAVE_ANTWORT, {
          antwort1: this.fragen[0].antwort,
          antwort2: this.fragen[1].antwort,
          antwort3: this.fragen[2].antwort,
          kommentar: this.kommentar,
          umfrageId: this.activeUmfrage.id,
        })
        .then(() => {
          this.isLoading = false;
          this.toast('Umfrage erfolgreich beantwortet!');
          hide();
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.umfragen-widget {
  cursor: pointer;
  position: absolute;
  height: 50px;
  width: 50px;
  right: 25px;
  bottom: 25px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  z-index: 99;
}
:deep(.modal-footer) {
  padding: 0.8rem;
}
:deep(.modal-header) {
  padding: 1rem;
}
</style>
