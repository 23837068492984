<template>
  <FilterOverview title="Umfragenübersicht" :showFilterInitially="false" @reload="getUmfragen">
    <template #toolbar-right>
      <b-button variant="primary" size="sm" v-b-modal.createUmfrageModal>Umfrage erstellen</b-button>
    </template>
    <template #table="{ tableHeight }">
      <div :style="{ height: tableHeight + 80 + 'px' }" class="card card-body" style="overflow-x: auto">
        <b-table
          :busy="isLoading"
          :items="umfragen"
          :fields="fields"
          bordered
          :tbody-tr-class="rowClass"
          head-variant="light"
        >
          <template v-slot:cell(isActive)="row">
            <div @click="onClickToggleActive(row.item)" style="cursor: pointer">
              <div v-if="row.item.isActive" class="led-green"></div>
              <div v-else class="led-red"></div>
            </div>
          </template>
          <template v-slot:cell(anzahlAntworten)="row">
            {{ row.item.antworten.length }}
          </template>
          <template v-slot:cell(averageFrage1)="row">
            <StarRating
              v-if="!isNaN(durchschnittFrage(row.item)?.[0])"
              :rating="durchschnittFrage(row.item)?.[0]"
            />
            <div v-else>-</div>
          </template>
          <template v-slot:cell(averageFrage2)="row">
            <StarRating
              v-if="!isNaN(durchschnittFrage(row.item)?.[1])"
              :rating="durchschnittFrage(row.item)?.[1]"
            />
            <div v-else>-</div>
          </template>
          <template v-slot:cell(averageFrage3)="row">
            <StarRating
              v-if="!isNaN(durchschnittFrage(row.item)?.[2])"
              :rating="durchschnittFrage(row.item)?.[2]"
            />
            <div v-else>-</div>
          </template>
          <template v-slot:cell(actions)="row">
            <div style="display: flex; gap: 4px">
              <ActionButton
                @click="onToggleDetailsClick($event, row)"
                :iconClass="row.detailsShowing ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
                title="Ausklappen"
              />
              <ActionButton
                @click="onDeleteUmfrageClick(row.item)"
                iconClass="b-icon-trash"
                title="Löschen"
              />
            </div>
          </template>
          <template slot="row-details" slot-scope="row" v-if="row.item._showDetails">
            <h5>Bewertungsübersicht:</h5>
            <b-table
              :fields="fieldsDetailTable"
              :items="getTableDetailsData(row.item.antworten)"
              head-variant="light"
            >
              <template #head(bewertungen1)="data">
                <StarRating :rating="1" removeAverage />
              </template>
              <template #head(bewertungen2)="data">
                <StarRating :rating="2" removeAverage />
              </template>
              <template #head(bewertungen3)="data">
                <StarRating :rating="3" removeAverage />
              </template>
              <template #head(bewertungen4)="data">
                <StarRating :rating="4" removeAverage />
              </template>
              <template #head(bewertungen5)="data">
                <StarRating :rating="5" removeAverage />
              </template>

              <template v-slot:cell(bewertungen1)="row">
                <p style="cursor: pointer" :id="'bewertungen-1' + row.item.frage">
                  {{ row.item.bewertungen[1] }}
                </p>
                <b-tooltip :target="'bewertungen-1' + row.item.frage" placement="left">
                  {{ row.item.bewerter[1]?.join(', ') }}
                </b-tooltip>
              </template>
              <template v-slot:cell(bewertungen2)="row">
                <p style="cursor: pointer" :id="'bewertungen-2' + row.item.frage">
                  {{ row.item.bewertungen[2] }}
                </p>
                <b-tooltip :target="'bewertungen-2' + row.item.frage" placement="left">
                  {{ row.item.bewerter[2]?.join(', ') }}
                </b-tooltip>
              </template>
              <template v-slot:cell(bewertungen3)="row">
                <p style="cursor: pointer" :id="'bewertungen-3' + row.item.frage">
                  {{ row.item.bewertungen[3] }}
                </p>
                <b-tooltip :target="'bewertungen-3' + row.item.frage" placement="left">
                  {{ row.item.bewerter[3]?.join(', ') }}
                </b-tooltip>
              </template>
              <template v-slot:cell(bewertungen4)="row">
                <p style="cursor: pointer" :id="'bewertungen-4' + row.item.frage">
                  {{ row.item.bewertungen[4] }}
                </p>
                <b-tooltip :target="'bewertungen-4' + row.item.frage" placement="left">
                  {{ row.item.bewerter[4]?.join(', ') }}
                </b-tooltip>
              </template>
              <template v-slot:cell(bewertungen5)="row">
                <p style="cursor: pointer" :id="'bewertungen-5' + row.item.frage">
                  {{ row.item.bewertungen[5] }}
                </p>
                <b-tooltip :target="'bewertungen-5' + row.item.frage" placement="left">
                  {{ row.item.bewerter[5]?.join(', ') }}
                </b-tooltip>
              </template>
            </b-table>
            <!-- <div style="display: grid; grid-template-columns: repeat(4, 1fr)"> -->
            <div class="mt-6" v-if="getFilteredAntworten(row.item.antworten)?.length > 0">
              <h5>Kommentare:</h5>
              <div class="d-flex flex-wrap" style="gap: 12px">
                <div
                  v-for="antwort in getFilteredAntworten(row.item.antworten)"
                  :key="'kommentar-' + antwort.kommentar"
                  class="card bg-secondary p-2"
                  style="flex-grow: 1; max-width: 500px"
                >
                  <p class="mb-2" style="font-weight: bold">
                    {{ $store.getters.getUsernameById(antwort.userId) || 'Unbekannter Nutzer' }}
                  </p>
                  <span class="pl-2" style="font-size: 1.1rem">{{ `"${antwort.kommentar}"` }}</span>
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </div>
      <CreateUmfrageModal />
    </template>
  </FilterOverview>
</template>

<script>
import ActionButton from '@/components/common/action-button.vue';

import FilterOverview from '@/components/common/filter-overview.vue';
import { GET_UMFRAGEN, TOGGLE_ACTIVE_UMFRAGE, DELETE_UMFRAGE } from '@/core/umfragen/umfragen.module';
import { mapState } from 'vuex';
import StarRating from '@/components/common/star-rating';
import CreateUmfrageModal from '@/components/umfragen/create-umfrage-modal';

export default {
  components: {
    FilterOverview,
    StarRating,
    CreateUmfrageModal,
    ActionButton,
  },
  mounted() {
    this.getUmfragen();
  },
  data() {
    return {
      fillPercentage: 25,
      fields: [
        {
          key: 'createdDateUtc',
          label: 'Erstellungsdatum',
          formatter: value => {
            return this.$options.filters.date(value);
          },
        },
        {
          key: 'anzahlAntworten',
          label: 'Anzahl Antworten',
        },
        { key: 'ueberschrift', label: 'Überschrift' },
        {
          key: 'averageFrage1',
          label: '⌀ Frage 1',
        },
        {
          key: 'averageFrage2',
          label: '⌀ Frage 2',
        },
        {
          key: 'averageFrage3',
          label: '⌀ Frage 3',
        },
        {
          key: 'team',
          label: 'Team',
        },
        {
          key: 'isActive',
          label: 'Aktiv',
        },
        {
          key: 'actions',
          label: 'Aktionen',
        },
      ],
      fieldsDetailTable: [
        { key: 'frage' },
        { key: 'bewertungen1', label: '1 Stern' },
        { key: 'bewertungen2', label: '2 Sterne' },
        { key: 'bewertungen3', label: '3 Sterne' },
        { key: 'bewertungen4', label: '4 Sterne' },
        { key: 'bewertungen5', label: '5 Sterne' },
      ],
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      umfragen: state => state.umfragen.umfragen,
    }),
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.isActive) return 'bg-green';
    },
    durchschnittFrage(umfrage) {
      if (umfrage.antworten.length === 0) return null;

      const antwortenAccumulated = umfrage.antworten.reduce((acc, antwort, id) => {
        if (id === 0) {
          acc[0] = antwort.antwort1;
          acc[1] = antwort.antwort2;
          acc[2] = antwort.antwort3;
          return acc;
        }
        acc[0] += antwort.antwort1;
        acc[1] += antwort.antwort2;
        acc[2] += antwort.antwort3;
        return acc;
      }, []);
      return antwortenAccumulated.map(
        antwortAcc => Math.round((antwortAcc / umfrage.antworten.length) * 100) / 100
      );
    },
    onClickToggleActive(item) {
      this.$store.dispatch(TOGGLE_ACTIVE_UMFRAGE, { umfrageId: item.id }).then(() => this.getUmfragen());
    },
    async getUmfragen() {
      this.isLoading = true;
      await this.$store.dispatch(GET_UMFRAGEN);
      this.isLoading = false;
    },
    async onDeleteUmfrageClick(item) {
      const confirmed = await this.$bvModal.msgBoxConfirm('Willst du die Umfrage wirklich löschen?', {
        centered: true,
        okTitle: 'OK',
        cancelTitle: 'Abbrechen',
      });
      if (confirmed) {
        this.isLoading = true;
        await this.$store
          .dispatch(DELETE_UMFRAGE, { umfrageId: item.id })
          .then(async () => await this.getUmfragen());
        this.isLoading = false;
      }
    },
    onToggleDetailsClick($event, row) {
      row.toggleDetails();
    },
    getTableDetailsData(data) {
      const bewertungen = antwortX =>
        data?.reduce(
          (acc, curr) => {
            acc[curr[antwortX]]++;
            return acc;
          },
          [...Array(6).fill(0)]
        );

      const bewerter = antwortX =>
        data?.reduce(
          (acc, curr) => {
            const user = this.$store.getters.getUsernameById(curr.userId);

            if (!acc[curr[antwortX]]) {
              acc[curr[antwortX]] = [user];
              return acc;
            }
            acc[curr[antwortX]] = [...acc[curr[antwortX]], user];
            return acc;
          },
          [...Array(6).fill(null)]
        );

      const bewertungen1 = bewertungen('antwort1');
      const bewertungen2 = bewertungen('antwort2');
      const bewertungen3 = bewertungen('antwort3');

      const bewerter1 = bewerter('antwort1');
      const bewerter2 = bewerter('antwort2');
      const bewerter3 = bewerter('antwort3');

      const dataTable = [
        {
          frage: 'Wie zufrieden bist du mit den neuen Features?',
          bewertungen: bewertungen1,
          bewerter: bewerter1,
        },
        {
          frage: 'Wie nutzerfreundlich findest du die neuen Features?',
          bewertungen: bewertungen2,
          bewerter: bewerter2,
        },
        {
          frage: 'Wie gefällt dir die Benutzeroberfläche?',
          bewertungen: bewertungen3,
          bewerter: bewerter3,
        },
      ];
      return dataTable;
    },
    getFilteredAntworten(data) {
      return data.filter(antwort => antwort.kommentar?.length > 0);
    },
  },
};
</script>
<style scoped>
.led-green {
  width: 16px;
  height: 16px;
  background-color: #abff0080;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 4.5px, #89ff00 0 1px 6px;
}

.led-red {
  width: 16px;
  height: 16px;
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -0.5px 3.5px 0.5px, inset #441313 0 -1px 4.5px,
    rgba(255, 0, 0, 0.5) 0 1px 6px;
}
:deep(.bg-green) {
  background-color: #28a74530 !important;
}
:deep(table) {
  font-size: 1.05rem !important;
}
:deep(th) {
  font-size: 1.1rem !important;
}
</style>
