var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header card-header-tabs-line"},[_c('div',{staticClass:"card-title"},[_c('ActionButton',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sheetdaten-collapse",modifiers:{"sheetdaten-collapse":true}}],staticClass:"mr-3",attrs:{"title":"Ausklappen","iconClass":_vm.isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'},on:{"click":_vm.toggleCollapse}}),_c('h3',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.correctTitle))]),_c('div',{staticClass:"card-toolbar"})],1),_c('ul',{staticClass:"nav nav-tabs nav-bold nav-tabs-line"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{
            path: '',
            query: { ...this.$route.query, sheetdatatab: 'flugdaten' },
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{staticClass:"nav-link",class:{ active: _vm.activeTabFromRouter === 'flugdaten' },attrs:{"href":href}},[_c('span',{staticClass:"nav-icon"},[_c('i',{staticClass:"fas fa-plane"})]),_c('span',{staticClass:"nav-text"},[_vm._v("Flugdaten")])])]}}])})],1),(_vm.showAbwicklungssheet)?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{
            path: '',
            query: { ...this.$route.query, sheetdatatab: 'abwicklungssheet' },
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{staticClass:"nav-link",class:{ active: _vm.activeTabFromRouter === 'abwicklungssheet' },attrs:{"href":href}},[_c('span',{staticClass:"nav-icon"},[_c('i',{staticClass:"fas fa-list"})]),_c('span',{staticClass:"nav-text"},[_vm._v("Abwicklungssheet")])])]}}],null,false,1797282860)})],1):_vm._e()])]),_c('b-collapse',{attrs:{"visible":"","id":"sheetdaten-collapse"}},[_c('div',{staticClass:"card-body"},[(_vm.showAbwicklungssheet)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabFromRouter === 'abwicklungssheet'),expression:"activeTabFromRouter === 'abwicklungssheet'"}],staticClass:"tab-pane active",class:{ show: _vm.activeTabFromRouter === 'abwicklungssheet' }},[(_vm.sheetdata?.abwicklungssheet)?_c('div',{staticClass:"d-flex align-items-baseline py-2 overflow-auto scroll scroll-pull",attrs:{"data-scroll":"true","data-wheel-propagation":"true"}},_vm._l((_vm.sheetdata.abwicklungssheet),function(value,key){return _c('div',{key:key + value,staticClass:"mb-2 flex-shrink-0"},[(value !== '' && value !== '-')?_c('div',{staticClass:"mr-6 d-flex flex-column align-items-baseline"},[_c('div',{staticClass:"font-weight-bold mb-4"},[_vm._v(" "+_vm._s(key)+" ")]),_c('span',{staticClass:"rounded p-3 bg-light-primary text-primary",staticStyle:{"max-width":"400px","white-space":"pre-line"}},[_vm._v(" "+_vm._s(value)+" ")])]):_vm._e()])}),0):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabFromRouter === 'flugdaten'),expression:"activeTabFromRouter === 'flugdaten'"}],staticClass:"tab-pane active",class:{ show: _vm.activeTabFromRouter === 'flugdaten' }},[_c('ReiseterminCardFlugdataReleasepruefung',{attrs:{"reiseterminObj":_vm.reiseterminObj}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }