<template>
  <div>
    <portal to="subheader-left">
      <ul class="nav nav-light-primary nav-bold nav-pills py-2" v-if="reiseterminId">
        <router-link
          custom
          :to="{ name: 'reisetermine-view', params: { reiseterminId: reiseterminId } }"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li class="nav-item">
            <a
              class="nav-link px-xs-3 px-lg-4"
              :class="{ 'menu-item-active': isActive, active: $route.name === 'reisetermine-view' }"
              :href="href"
              @click="navigate"
            >
              <span>Details</span>
            </a>
          </li>
        </router-link>
        <router-link
          custom
          :to="{ name: 'reisetermin-view-releasepruefung', params: { reiseterminId: reiseterminId } }"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li class="nav-item">
            <a
              class="nav-link px-xs-3 px-lg-4"
              :class="{
                'menu-item-active': isActive,
                active: $route.name === 'reisetermin-view-releasepruefung',
              }"
              :href="href"
              @click="navigate"
            >
              <span>Releaseprüfung</span>
            </a>
          </li>
        </router-link>
      </ul>
    </portal>
    <div ref="intersectWatcher"></div>

    <ReiseterminViewHeader
      :isInitialLoading="isInitialLoading"
      :reiseterminObj="reiseterminObj"
    ></ReiseterminViewHeader>

    <ReiseterminViewDetailHeaderCompact
      v-if="!$store.state.userSettingsLocal.alwaysHideReiseterminCompactHeader"
      v-show="showCompactHeader"
      :isInitialLoading="isInitialLoading"
      :reiseterminObj="reiseterminObj"
      @triggerPressed="loadData"
    ></ReiseterminViewDetailHeaderCompact>

    <router-view
      :reiseterminObj="reiseterminObj"
      @updated-vorgang="loadData"
      @releaseprocess-updated="releaseProzessVorgaengeUpdate"
    ></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as odataService from '@/core/common/services/odata.service';
import EventBus from '@/core/common/plugins/event-bus.js';
import { VISITED_REISETERMIN } from '@/core/user/stores/user-settings-local.module';
import ReiseterminViewHeader from '@/components/produkte/reisetermine/reisetermin-view-header.vue';
import ReiseterminViewDetailHeaderCompact from '@/components/produkte/reisetermine/reisetermin-view-detail-header-compact.vue';

export default {
  name: 'ReisetermineViewComponent',
  components: {
    ReiseterminViewHeader,
    ReiseterminViewDetailHeaderCompact,
  },
  props: {
    reiseterminId: Number,
  },
  data() {
    return {
      observer: null,
      showCompactHeader: false,
      isInitialLoading: false,
      isLoading: false,
      reiseterminObj: null,
    };
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
    });
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  watch: {
    triggerSent() {
      //if state has changed update reisetermin
      this.loadData();
    },
    reiseterminId() {
      this.isInitialLoading = true;
      this.loadData();
    },
  },
  computed: {
    ...mapState({
      triggerSent: state => state.workflowManagerReisetermin.triggerSent,
    }),
  },
  beforeDestroy() {
    EventBus.$off('reload');
  },
  mounted() {
    this.isInitialLoading = true;
    this.loadData();
    this.observer.observe(this.$refs['intersectWatcher']);
    EventBus.$on('reload', () => {
      this.loadData();
    });
  },
  methods: {
    onAufgabeBearbeitet(changedAufgabe) {
      this.reiseterminObj.aufgaben = this.reiseterminObj.aufgaben.map(aufgabe => {
        if (aufgabe.id === changedAufgabe.id) return changedAufgabe;
        return aufgabe;
      });
    },
    onElementObserved(element) {
      this.showCompactHeader = element[0].intersectionRatio === 0;
    },

    async loadData() {
      this.isLoading = true;
      this.reiseterminObj = await this.getReisetermin();
      this.isLoading = false;
    },
    releaseProzessVorgaengeUpdate() {
      const expand = {
        comments: {},
        pax: {},
        vorgangReleaseprozess: {},
        Metadata: { expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} } },
        reise: {
          expand: {
            konfigurationen: {},
          },
        },
        gueltigeKonfiguration: {},
      };
      const filter = [{ id: parseInt(this.reiseterminId) }];
      const reisetermin = odataService.getReisetermin({ filter, expand });
      return reisetermin.then(response => {
        this.reiseterminObj.reiseunterlagenGeprueft = response.data[0].reiseunterlagenGeprueft;
        this.reiseterminObj.transferplanungGeprueft = response.data[0].transferplanungGeprueft;
        this.reiseterminObj.vorgangReleaseprozess = response.data[0].vorgangReleaseprozess;
      });
    },
    async getReisetermin() {
      const reiseterminFilter = { id: parseInt(this.reiseterminId) };
      const expand = {
        comments: {},
        pax: {},
        flugeinkauf: {},
        vorgangReleaseprozess: {},
        aufgaben: { expand: { comments: {} } },
        Metadata: { expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} } },
        reise: {
          expand: {
            konfigurationen: {},
          },
        },
        gueltigeKonfiguration: {},
        ersatztermin: {},
        ursprungsreisetermin: {},
      };
      const filter = [reiseterminFilter];
      return odataService
        .getReisetermin({ filter, expand })
        .then(response => {
          this.$store.dispatch(VISITED_REISETERMIN, {
            id: response.data[0].id,
            kuerzel: response.data[0].reiseterminkuerzel,
          });
          return response.data[0];
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isInitialLoading = false;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    document.title += ' / ' + to.params.reiseterminId;
    next();
  },
  beforeRouteUpdate(to, from, next) {
    document.title += ' / ' + to.params.reiseterminId;
    next();
  },
};
</script>

<style lang="scss" scoped>
.more-btn {
  cursor: pointer;
}
</style>
