<template>
  <div>
    <span ref="kunden-name-span" v-if="teilnehmende" class="text-nowrap d-flex flex-column">
      <div v-for="(item, index) in teilnehmende" :key="index">
        <template v-if="isMainTeilnehmde(item)">
          <b>{{ convertCaps(item.name) }}</b>
        </template>
        <template v-else>{{ convertCaps(item.name) }}</template>
        <template v-if="index !== teilnehmende.length - 1">,</template>
      </div>
    </span>
    <span v-else>
      <b>{{ kunde.vorname }} {{ kunde.nachname }}</b>
    </span>
    <b-popover
      :target="() => $refs['kunden-name-span']"
      triggers="hover"
      placement="bottom"
      boundary="viewport"
    >
      <table class="table table-sm table-borderless">
        <thead>
          <tr>
            <th class="p-0"></th>
            <th class="p-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-muted">
              Kunden
              <wbr />
              nummer:
            </td>
            <td id="foo">
              <ClickToCopyText :value="kunde.id" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('AUTH.INPUT.EMAIL') }}:</td>
            <td>
              <ClickToCopyText :value="kunde.email" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.TELEFONPRIVATSHORT') }}:</td>
            <td>
              <ClickToCopyText :value="telefonPrivat" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.TELGESCHAEFTLICHSHORT') }}:</td>
            <td>
              <ClickToCopyText :value="telefonGeschaeftlich" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.TELMOBILSHORT') }}:</td>
            <td>
              <ClickToCopyText :value="telefonMobil" />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('KUNDEN.DUPLIKATE') }}:</td>
            <td>
              {{ duplicateIds }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-popover>
  </div>
</template>
<style scoped></style>
<script>
import ClickToCopyText from '@/components/common/click-to-copy-text.vue';
export default {
  components: {
    ClickToCopyText,
  },
  props: {
    kunde: { type: Object },
    teilnehmende: {
      type: Array,
    },
  },
  computed: {
    duplicateIds() {
      const duplicateIdArray = this.kunde?.kunden?.map(kunde => kunde.id).filter(id => id !== this.kunde.id);
      return Array.isArray(duplicateIdArray) && duplicateIdArray.length > 0
        ? duplicateIdArray.join(',')
        : '-';
    },
    telefonPrivat() {
      return this.kunde?.telefonPrivat || '';
    },
    telefonGeschaeftlich() {
      return this.kunde?.telefonGeschaeftlich || '';
    },
    telefonMobil() {
      return this.kunde?.telefonMobil || '';
    },
  },
  methods: {
    isMainTeilnehmde(teilnehmende) {
      const aktuelleUebereinstimmung = this.uebereinstimmung(teilnehmende);
      const alleUebereinstimmungen = this.teilnehmende.map(teilnehmer => this.uebereinstimmung(teilnehmer));
      const maximaleUebereintimmung = Math.max(...alleUebereinstimmungen);

      return aktuelleUebereinstimmung === maximaleUebereintimmung;
    },

    editDistance(s1, s2) {
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();

      var costs = new Array();
      for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
          if (i == 0) costs[j] = j;
          else {
            if (j > 0) {
              var newValue = costs[j - 1];
              if (s1.charAt(i - 1) != s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
              costs[j - 1] = lastValue;
              lastValue = newValue;
            }
          }
        }
        if (i > 0) costs[s2.length] = lastValue;
      }
      return costs[s2.length];
    },

    uebereinstimmung(teilnehmende) {
      const kundenName = `${this.kunde.vorname} ${this.kunde.nachname}`.trim().toLowerCase();
      const teilnehmendeName = teilnehmende.name.toLowerCase();

      if (teilnehmendeName.includes(kundenName)) {
        return 1.0;
      }
      const longerLength = Math.max(kundenName.length, teilnehmendeName.length);

      return (longerLength - this.editDistance(kundenName, teilnehmendeName)) / parseFloat(longerLength);
    },

    convertCaps(teilnehmendenName) {
      const name = teilnehmendenName;
      const convertedName = name.replace(
        /(\w)(\w*)/g,
        (_, firstChar, rest) => firstChar + rest.toLowerCase()
      );
      return convertedName;
    },
  },
};
</script>
